import { Link } from "react-router-dom";
import { useRef } from "react";
import { useResolvedPath, useMatch } from "react-router-dom";
import useWindowDimensions from "./widthFinder";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import title_long from "./assets/alpha_long.png";
import title_short from "./assets/alpha_short.png";
import realms from "./assets/realms-b.png";
import about from "./assets/about.png";
import store from "./assets/store.png";
import tools from "./assets/tools.png";
import menu from "./assets/menu.png";

function TitleLogo({ width }) {
  if (width >= 725) {
    return <img src={title_long} alt="Alpha Network Home" height="25rem"></img>;
  } else {
    return <img src={title_short} alt="Alpha Network Home" height="50rem"></img>;
  }
}
function TitleLogoMobile({ width }) {
  if (width >= 400) {
    return <img className="imgtest" src={title_long} alt="Alpha Network Home" height="25rem" style={{ verticalAlign: "center" }}></img>;
  } else {
    return <img src={title_short} alt="Alpha Network Home" height="50rem"></img>;
  }
}

function menuHeight(h) {
  if (h >= 400) {
    return "25rem";
  } else return "25rem";
}

export default function Navbar() {
  const { height, width } = useWindowDimensions();
  if (width > 600) {
    return (
      <nav className="nav">
        <Link to="/" className="site-title">
          <TitleLogo width={width}></TitleLogo>
        </Link>
        <ul>
          <li>
            <CustomLink to="/realms">
              <img src={realms} alt="Realms" height="25rem"></img>
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/about">
              <img src={about} alt="About" height="25rem"></img>
            </CustomLink>
          </li>
          <li>
            <CustomLink to="https://alpha-network-store.tebex.io/">
              <img src={store} alt="Store" height="25rem"></img>
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/tools">
              <img src={tools} alt="Tools" height="25rem"></img>
            </CustomLink>
          </li>
        </ul>
      </nav>
    );
  } else {
    return (
      <div className="header">
        <div className="nav">
          <Link to="/" className="site-title">
            <TitleLogoMobile width={width}></TitleLogoMobile>
          </Link>

          <div className="dropdown">
            <button className="dropbtn">
              <img src={menu} alt="Menu" height={menuHeight(width)}></img>
            </button>
            <div className="dropdown-content">
              <Link to="/realms">
                <img src={realms} alt="Realms" height="20rem"></img>
              </Link>
              <Link to="/about">
                <img src={about} alt="About" height="20rem"></img>
              </Link>
              <Link to="https://alpha-network-store.tebex.io/">
                <img src={store} alt="Store" height="20rem"></img>
              </Link>
              <Link to="/tools">
                <img src={tools} alt="Tools" height="20rem"></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function CustomLink({ to, children, ...props }) {
  const reslovedPath = useResolvedPath(to);
  const isActive = useMatch({ path: reslovedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : "off"}>
      <Link to={to}>{children}</Link>
    </li>
  );
}
