import React, { memo } from "react";
import tiktok from "../assets/tiktok.png";
import discord from "../assets/discord.png";
import realms from "../assets/realms.png";
import tebex from "../assets/tebex.png";
import { Link } from "react-router-dom";
import logo from "../logo.svg";

const Footer = memo(() => {
  return (
    <div className="foot">
      <hr></hr>
      <footer>
        <div className="footerText">
          <h3>Alpha Network</h3>
          <img alt="alpha network logo" style={{ width: "70px" }} src={logo}></img>
          <p>
            Join our discord to claim a free discord kit
            <Link id="linkDC" to="https://discord.gg/yK66xcUUGp">
              here on the discord
            </Link>
          </p>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/realms">Realms</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="https://alpha-network-store.tebex.io/">Store</Link>
            </li>
          </ul>
        </div>
        <hr id="seperator"></hr>
        <div className="footer">
          <ul>
            <li>
              <Link to="https://www.tiktok.com/@alpha_network_">
                <img alt="tiktok link" src={tiktok}></img>
              </Link>
            </li>
            <li>
              <Link to="https://discord.gg/yK66xcUUGp">
                <img alt="link to the discord server" src={discord}></img>
              </Link>
            </li>
            <li>
              <Link to="/realms">
                <img alt="realms link" src={realms}></img>
              </Link>
            </li>
            <li>
              <Link to="https://alpha-network-store.tebex.io/">
                <img alt="tebex link" src={tebex}></img>
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
});

export default Footer;
