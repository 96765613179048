import React, { useState } from "react";
import ShopItem from "./ShopItemComp";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
let i = 0;
export default function ShopCategory({ parentShopId, idCat, sessionID }) {
  const [image, setImage] = useState(null);
  const [file, setFile] = useState("./default.png");
  const audio = new Audio("/minecraft_click3.mp3");
  const [shopItems, addShopItem] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [shopStorage, setStorage] = useState([]);
  // let infoObject = {
  //   filePath: file,
  //   itemName: shopItemName,
  //   itemIdentifier: shopItemIdentifier,
  //   itemPrice: shopItemPrice,
  //   parentId: parentShopId,
  //   itemId: itemId,
  // };
  function shopInfoBuilder(info) {
    console.log("EEE", info);
    const exists = localStorage.getItem(`shop:${parentShopId}`);
    if (exists === null) {
      localStorage.setItem(`shop:${parentShopId}`, JSON.stringify([info]));
    } else {
      let storedInfo = JSON.parse(localStorage.getItem(`shop:${parentShopId}`));
      let found = false;
      for (let index = 0; index < storedInfo.length; index++) {
        console.log(index, storedInfo.length);
        if (storedInfo[index].itemId === info.itemId) {
          found = true;
          console.log("found", found);
          storedInfo[index] = info;
          localStorage.setItem(`shop:${parentShopId}`, JSON.stringify(storedInfo));
          return;
        }
        if (index === storedInfo.length - 1) {
          console.log("Not Found, Added");
          storedInfo.push(info);
          localStorage.setItem(`shop:${parentShopId}`, JSON.stringify(storedInfo));
        }
      }
    }
  }

  // async function doUpdates(info) {
  //   let parsedValue = localStorage.getItem(`shop:${parentShopId}`);
  //   let newValue = [...shopStorage];
  //   shopStorage.forEach((item, index) => {
  //     if (item.itemId === info.itemId) {
  //       newValue[index] = info;
  //       console.log(parsedValue.length, index);
  //       if (parsedValue.length === index + 1) {
  //         localStorage.setItem(`shop:${parentShopId}`, JSON.stringify(newValue));
  //         return 1;
  //       }
  //     }
  //   });
  // }

  const catNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const shopItemAdd = () => {
    audio.play();
    i++;

    addShopItem(shopItems.concat(<ShopItem idCat={idCat} parentShopId={parentShopId} shopInfoBuilder={shopInfoBuilder} itemId={i} key={uuidv4()}></ShopItem>));
  };

  const handleChange = (e) => {
    if (e.target.files[0].size > 5242880) {
      alert("File To Big - 5MB Limit");
      return;
    }

    if (e.target.files[0].name.endsWith(".png") || e.target.files[0].name.endsWith(".jpg") || e.target.files[0].name.endsWith(".jpeg") || e.target.files[0].name.endsWith(".gif")) {
      setFile(URL.createObjectURL(e.target.files[0]));

      const data = new FormData();
      //FILE INFO NAME WILL BE "my-image-file"
      data.append(`${sessionID}`, e.target.files[0], e.target.files[0].name);
      setImage(data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "http://play.alphanetworkmc.com:3006/image-upload",
        headers: {
          sessionID: sessionID,
          ...data.getHeaders(),
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      axios.post("http://play.alphanetworkmc.com:3006/image-upload", image).then((res) => {
        console.log("Axios response: ", res);
      });
    } else {
      alert("Only Image Files Can Be Used");
      return;
    }
  };
  return (
    <div className="category-icon">
      <div style={{ display: "reletive" }}>
        <label for="icon">Category Icon</label> <input id="icon" name="Icon" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={handleChange} />
        <img style={{ width: "32px", height: "32px", position: "absolute" }} alt={"User Uploaded Icon"} src={file} />
      </div>
      <br></br>
      <label for="cat-name">Category Name</label>
      <input id="cat-name" type="text" placeholder="Category Name" onChange={catNameChange}></input>
      <div>
        <button className="button-generic" onClick={(e) => shopItemAdd(e)}>
          Add Item
        </button>
      </div>
      {shopItems}
      <hr id="cat-end"></hr>
    </div>
  );
}
