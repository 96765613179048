import React, { useEffect, useState } from "react";

export default function ShopItem({ shopInfoBuilder, parentShopId, itemId, idCat }) {
  const [file, setFile] = useState("./default.png");
  const [shopItemName, setShopItemName] = useState("");
  const [shopItemIdentifier, setShopItemIdentifier] = useState("");
  const [shopItemPrice, setShopItemPrice] = useState(0);

  const itemNameChange = (e) => {
    setShopItemName(e.target.value);
  };
  const itemIdentifierChange = (e) => {
    setShopItemIdentifier(e.target.value);
  };
  const itemPriceChange = (e) => {
    setShopItemPrice(e.target.value);
  };

  const handleChange = (e) => {
    console.log("ran");
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const deleteShopItem = (e) => {};

  useEffect(() => {
    let infoObject = {
      filePath: file,
      itemName: shopItemName,
      itemIdentifier: shopItemIdentifier,
      itemPrice: shopItemPrice,
      parentId: parentShopId,
      itemId: itemId,
      idCat: idCat,
    };
    shopInfoBuilder(infoObject);

    // let currentValue = localStorage.getItem(`shop:${parentShopId}`);
    // if (currentValue === null) {
    //   localStorage.setItem(`shop:${parentShopId}`, JSON.stringify([infoObject]));
    // } else {
    //   let parsedValue = JSON.parse(currentValue);
    //   let newValue = parsedValue;
    //   console.log(parsedValue)
    //   parsedValue.forEach((item, index) => {
    //     if (item.itemId == infoObject.itemId) {
    //       console.log("E", infoObject.itemId);
    //       newValue[index] = infoObject;
    //       console.log(parsedValue.length, index);
    //       if (parsedValue.length == index + 1) {
    //         localStorage.setItem(`shop:${parentShopId}`, JSON.stringify(newValue));
    //       }
    //     }
    //   });
    // }
  });

  return (
    <div className="shop-items">
      Shop Item Icon
      <input type="file" onChange={handleChange} style={{ width: "20%" }} />
      <img style={{ width: "32px", height: "32px" }} alt={"Uploaded Icon"} src={file} />
      <br></br>
      <div className="shop-input-row">
        <input type="text" maxLength="30" placeholder="Item Name" onChange={(e) => itemNameChange(e)}></input>
        <input type="text" maxLength="30" placeholder="Item Identifier" onChange={(e) => itemIdentifierChange(e)}></input>
        <input type="number" max="2000000000" placeholder="Price" onChange={(e) => itemPriceChange(e)}></input>
        <button onClick={deleteShopItem(shopItemIdentifier)} title="Delete">
          ❌
        </button>
      </div>
    </div>
  );
}
