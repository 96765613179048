import skyblock from "../assets/skyblock.png";
import kit from "../assets/kit.png";
import factions from "../assets/factions-server.png";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.jsx";
import axios from "axios";
import { useState, useEffect, useRef } from "react";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function FactionsPlayerInfo() {
  const [factionsPlayerCount, setFactionsPlayerCount] = useState(0);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://play.alphanetworkmc.com:3006/info-factions",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setFactionsPlayerCount(response.data.playersOnline);
      })
      .catch((error) => {
        setFactionsPlayerCount(0);
        console.log(error);
      });
  }, []);

  useInterval(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://play.alphanetworkmc.com:3006/info-factions",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setFactionsPlayerCount(response.data.playersOnline);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 30000);

  return <>{factionsPlayerCount}</>;
}
function SkyblockPlayerInfo() {
  const [skyblockPlayerCount, setSkyblockPlayerCount] = useState(0);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://play.alphanetworkmc.com:3006/info-factions",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setSkyblockPlayerCount(response.data.playersOnline);
      })
      .catch((error) => {
        setSkyblockPlayerCount(0);
        console.log(error);
      });
  }, []);

  useInterval(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://play.alphanetworkmc.com:3006/info-factions",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setSkyblockPlayerCount(response.data.playersOnline);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 30000);

  return <>{skyblockPlayerCount}</>;
}

export default function Realms() {
  return (
    <>
      <div className="realms">
        <h1>Our Realms</h1>
        <div className="skyblock">
          <img src={skyblock} alt="render of minecraft skyblock realm"></img>
          <div className="realm-container">
            <div className="realm-text">
              <h1>Alpha Skyblock</h1>
              <div className="playerCount">
                <SkyblockPlayerInfo></SkyblockPlayerInfo> Players Online
              </div>
              <p>
                Code: <Link to="https://realms.gg/-2RhdrHyz-w">-2RhdrHyz-w</Link>
              </p>
              <p>
                About: 🌎︱A popular skyblock, Many unique and well made features.<br></br> 🎈︱Frequent giveaways and enticing events.<br></br> 🤝︱Friendly and welcoming staff.<br></br> 💬︱A safe environment for work, socializing and more.<br></br> ⬆️︱Popular and ever growing realm and discord server.<br></br>
              </p>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="kitpvp">
          <img src={kit} alt="render of minecraft kitpvp realm"></img>
          <div className="realm-container2">
            <div className="realm-text">
              <h1>Alpha KitPvP</h1>
              Code: <Link to="https://realms.gg/gzaPFjtkAWc">gzaPFjtkAWc</Link>
              <p>
                With Kit PVP, you may play one of the most popular Minecraft gamemodes online for free. In addition, the realm is ideal for PvP battles (PVP). Your unique items will be added to a pre-set category of the Kit. Prepare to battle your friends and other players in Minecraft to see who will win.<br></br>
                <br></br> Each person who enters a Minecraft world has one goal: to get points. The Best Minecraft KitPvP Realms List was created to let players choose their favorite from the list. Play against other players to get reputation and points.<br></br>
                <br></br>The Kit PVP game may be easy if you utilize the best worlds accessible. Use the best strategies and methods to win. To increase your chances of earning more points faster, you must get acquainted with the Minecraft KitPvP world that you want to use.
              </p>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="kitpvp">
          <img src={factions} alt="render of minecraft kitpvp realm"></img>
          <div className="realm-container2">
            <div className="realm-text">
              <h1>Alpha Factions</h1>
              <div className="playerCount">
                <FactionsPlayerInfo></FactionsPlayerInfo> Players Online
              </div>
              URL: play.alphanetworkmc.com
              <p>
                Welcome to Alpha Factions, the premier Minecraft server where strategy, teamwork, and skill collide in an epic battle for dominance! Dive into a world where alliances are forged, rivalries are ignited, and the quest for supremacy never ends. Whether you’re a seasoned warrior or a cunning strategist, Alpha Factions offers an immersive experience tailored for every type of player.
                Features: Dynamic Faction System: Create or join powerful factions to build your empire, defend your territory, and conquer your enemies.<br></br>
                <br></br> Economy and Trading: Utilize our robust economy system to trade resources, buy powerful gear, and amass wealth. Epic Raids and Events. Player-Driven Community: Engage with a passionate community of players, where your reputation and alliances matter. Why Choose Alpha Factions?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile">
        <h1>Our Realms</h1>
        <img id="realm-m" src={skyblock} alt="render of minecraft skyblock realm"></img>
        <div className="realm-container-mobile">
          <h1>Alpha Skyblock</h1>
          <p>
            Code: <Link to="https://realms.gg/-2RhdrHyz-w">-2RhdrHyz-w</Link>
          </p>
          <p>
            About: 🌎︱A popular skyblock, Many unique and well made features.<br></br> 🎈︱Frequent giveaways and enticing events.<br></br> 🤝︱Friendly and welcoming staff.<br></br> 💬︱A safe environment for work, socializing and more.<br></br> ⬆️︱Popular and ever growing realm and discord server.<br></br>
          </p>
        </div>
        <hr></hr>
        <br></br>
        <img id="realm-m" src={kit} alt="render of minecraft kitpvp realm"></img>
        <div className="realm-container-mobile2">
          <h1>Alpha KitPvP</h1>
          Code: <Link to="https://realms.gg/gzaPFjtkAWc">gzaPFjtkAWc</Link>
          <p>
            With Kit PVP, you may play one of the most popular Minecraft gamemodes online for free. In addition, the realm is ideal for PvP battles (PVP). Your unique items will be added to a pre-set category of the Kit. Prepare to battle your friends and other players in Minecraft to see who will win.<br></br>
            <br></br> Each person who enters a Minecraft world has one goal: to get points. The Best Minecraft KitPvP Realms List was created to let players choose their favorite from the list. Play against other players to get reputation and points.<br></br>
            <br></br>The Kit PVP game may be easy if you utilize the best worlds accessible. Use the best strategies and methods to win. To increase your chances of earning more points faster, you must get acquainted with the Minecraft KitPvP world that you want to use.
          </p>
        </div>
        <hr></hr>
        <br></br>
        <img id="realm-m" src={factions} alt="render of minecraft factions server"></img>
        <div className="realm-container-mobile2">
          <h1>Alpha Factions</h1>
          URL: play.alphanetworkmc.com
          <p>
            Welcome to Alpha Factions, the premier Minecraft server where strategy, teamwork, and skill collide in an epic battle for dominance! Dive into a world where alliances are forged, rivalries are ignited, and the quest for supremacy never ends. Whether you’re a seasoned warrior or a cunning strategist, Alpha Factions offers an immersive experience tailored for every type of player.
            Features: Dynamic Faction System: Create or join powerful factions to build your empire, defend your territory, and conquer your enemies.<br></br>
            <br></br> Economy and Trading: Utilize our robust economy system to trade resources, buy powerful gear, and amass wealth. Epic Raids and Events. Player-Driven Community: Engage with a passionate community of players, where your reputation and alliances matter. Why Choose Alpha Factions?
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
