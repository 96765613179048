import React, { useState } from "react";

export default function FontFormater({ checkBoxStatus, updateCheckboxBold, updateCheckboxItalic, updateCheckboxRandom }) {
  return (
    <div className="format-options">
      <input
        type="checkbox"
        checked={checkBoxStatus.bold}
        onChange={() => {
          updateCheckboxBold();
        }}
      />
      <label>Bold</label>
      <br></br>
      <input
        type="checkbox"
        checked={checkBoxStatus.italic}
        onChange={() => {
          updateCheckboxItalic();
        }}
      />
      <label>Italic</label>
      <br></br>

      <input
        type="checkbox"
        checked={checkBoxStatus.random}
        onChange={() => {
          updateCheckboxRandom();
        }}
      />
      <label>Obfuscated</label>
    </div>
  );
}
