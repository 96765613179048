import realm from "../assets/realmlogo.png";
import Footer from "../components/Footer.jsx";

export default function About() {
  return (
    <>
      <div className="about">
        <h1>What are Realms</h1>
        <img src={realm} alt="minecraft realms logo"></img>
        <p>
          In addition to the regular PC and Pocket Edition versions of Minecraft, the game is also accessible on Xbox One and Windows 10 as well as on macOS, where you can get the Bedrock Edition for PC: Windows 10 ($27), the Bedrock Edition for Xbox One: Minecraft ($20) with over 100 games for one price.
          <br></br>
          <br></br>
          In order to access realms, you must log in to the same Minecraft account on all of the compatible devices. You may bring your pals to any platform using the cross-play feature. Keep in mind that if you wish to play the Java Edition on PC, Realms does not work with most devices.
        </p>
      </div>
      <Footer></Footer>
    </>
  );
}
