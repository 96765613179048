import React, { useState } from "react";
import ShopCategory from "./ShopCategory";
import { v4 as uuidv4 } from "uuid";

let i = 0;
export default function ShopBuilder({ shopId, sessionID }) {
  const audio = new Audio("/minecraft_click3.mp3");
  const [categories, addCategory] = useState([]);
  const categoryAdd = () => {
    i++;
    audio.play();
    addCategory(categories.concat(<ShopCategory sessionID={sessionID} parentShopId={shopId} idCat={i} key={uuidv4()}></ShopCategory>));
  };
  return (
    <div>
      <h3 style={Object.assign({ textDecoration: "underline" }, { fontWeight: "lighter" })}>Shop Builder</h3>
      <p style={{ fontSize: "x-small" }}>*Image Previews are shown In A 32x32 Format, They May Look Different In Game</p>
      <div>
        <button className="button-generic" onClick={categoryAdd}>
          Add Category
        </button>
      </div>
      <div>{categories}</div>
    </div>
  );
}
