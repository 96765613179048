import sample from "../assets/back_vid.mp4";
import { Link } from "react-router-dom";
import "../styles.css";
import Footer from "../components/Footer.jsx";
import home from "../assets/kit.png";

export default function Home() {
  return (
    <>
      <div className="home">
        <video poster={home} autoPlay muted loop id="myVideo">
          <source src={sample} type="video/mp4" />
        </video>

        <div className="content">
          <h1>ALPHA NETWORK</h1>
          <p>
            Our Realms are made to very high standards and updated frequently. Our Realms have been featured in multiple videos by popular youtubers showcasing the realm. We have also have high ratings on <a href="https://mcrealmhub.com/">mcrealmhub</a> as well as thousands of joins across our realms. We have also achieved realm of the month from&nbsp;
            <a href="https://discord.gg/buildarealm">buildarealm</a>
          </p>

          <p>We are a network that host Minecraft bedrock realms ranging from Skyblock , factions to kitpvp - We were established in June 2021 and have been growing ever since then! Come join our community to discover the amazing realms full of a wide variety of people, from competitive sweats to casual farmers!</p>
        </div>
      </div>
      <div className="mobile">
        <h1>ALPHA NETWORK</h1>
        <p>
          Our Realms are made to very high standards and updated frequently. Our Realms have been featured in multiple videos by popular youtubers showcasing the realm. We have also have high ratings on <a href="https://mcrealmhub.com/">mcrealmhub</a> as well as thousands of joins across our realms. We have also achieved realm of the month from&nbsp;
          <a href="https://discord.gg/buildarealm">buildarealm</a>
        </p>

        <p>We are a network that host Minecraft bedrock realms ranging from Skyblock , factions to kitpvp - We were established in June 2021 and have been growing ever since then! Come join our community to discover the amazing realms full of a wide variety of people, from competitive sweats to casual farmers!</p>
      </div>
      <div className="extra-info">
        <h1>More Info</h1>

        <p>
          You Can Find<a href="https://discord.gg/alpha-network-856580261965332500"> Our Discord Here</a>
        </p>
      </div>
      <Footer></Footer>
    </>
  );
}
