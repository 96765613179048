import React from "react";

export default function FormType({ formTypeSelect, formType }) {
  return (
    <div className="form-types">
      <input
        type="checkbox"
        onClick={() => {
          console.log("R", formType);
          formTypeSelect("shop");
        }}
        checked={formType.shop}
      />
      <label>Shop Menu</label> <br />
      <input
        type="checkbox"
        checked={formType.warp}
        onClick={() => {
          console.log("R", formType);
          formTypeSelect("warp");
        }}
      />
      <label>Warp Menu</label>
      <br />
      <input
        type="checkbox"
        checked={formType.home}
        onClick={() => {
          console.log("R", formType);
          formTypeSelect("home");
        }}
      />
      <label>Home Warp/Set Menu</label>
      <br />
      <input
        type="checkbox"
        checked={formType.money}
        onClick={() => {
          console.log("R", formType);
          formTypeSelect("money");
        }}
      />
      <label>Money Transfer Menu</label>
    </div>
  );
}
