import React, { useState } from "react";
import ColourGrid from "./colour-grid";

let audio = new Audio("/minecraft_click3.mp3");
export default function BodyText({ deleteText }) {
  const [show, toggleShow] = useState(false);
  const [colour, setColour] = useState("#eee090");
  const [text, setText] = useState("");

  const handleChange = () => {
    toggleShow(!show);
    audio.play();
  };

  function colourChosen(newColour) {
    setColour(newColour);

    handleChange();
  }

  function handleText(e) {
    // handleChange()
    setText(e.target.value);
  }

  function deleteTextN() {
    setText("");
    deleteText();
    audio.play();
  }

  return (
    <div className="bodytext">
      <textarea onChange={handleText} style={{ color: colour }} value={text}></textarea>
      <button onClick={deleteTextN} title="Delete Text Area" className="button-edit">
        ❌
      </button>
      <button className="button-edit" onClick={handleChange} title="Colour">
        🎨
      </button>
      {show ? <ColourGrid colourChosen={colourChosen}></ColourGrid> : undefined}
    </div>
  );
}
