import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Tools = () => {
  return (
    <>
      <div className="tools-page">
        <div className="tool-buttons-container">
          <Link to="/tools" className="tool-button">
            <div className="tool-button">
              <h2>Minecraft Bedrock GUI Creator - (Coming Soon)</h2>
              <p>This Tool Is In A Early Stage Of Development. There Is No Gurentee It Will Always Work Perfectly.</p>
            </div>
          </Link>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Tools;
