import React, { useState } from "react";

export default function ColourGrid({ colourChosen }) {
  return (
    <div className="colour-grid">
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#000000");
        }}
        style={{ backgroundColor: "#000000" }}>
        §0
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#0000AA");
        }}
        style={{ backgroundColor: "#0000AA" }}>
        §1
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#00AA00");
        }}
        style={{ backgroundColor: "#00AA00" }}>
        §2
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#00AAAA");
        }}
        style={{ backgroundColor: "#00AAAA" }}>
        §3
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#AA0000");
        }}
        style={{ backgroundColor: "#AA0000" }}>
        §4
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#AA00AA");
        }}
        style={{ backgroundColor: "#AA00AA" }}>
        §5
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#FFAA00");
        }}
        style={{ backgroundColor: "#FFAA00" }}>
        §6
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#AAAAAA");
        }}
        style={{ backgroundColor: "#AAAAAA" }}>
        §7
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#555555");
        }}
        style={{ backgroundColor: "#555555" }}>
        §8
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#5555FF");
        }}
        style={{ backgroundColor: "#5555FF" }}>
        §9
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#55FF55");
        }}
        style={{ backgroundColor: "#55FF55" }}>
        §a
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#55FFFF");
        }}
        style={{ backgroundColor: "#55FFFF" }}>
        §b
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#FF5555");
        }}
        style={{ backgroundColor: "#FF5555" }}>
        §c
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#FF55FF");
        }}
        style={{ backgroundColor: "#FF55FF" }}>
        §d
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#FFFF55");
        }}
        style={{ backgroundColor: "#FFFF55" }}>
        §e
      </div>
      <div
        className="colour-grid-item"
        onClick={() => {
          let audio = new Audio("/minecraft_click3.mp3");
          audio.play();
          colourChosen("#FFFFFF");
        }}
        style={{ backgroundColor: "#FFFFFF" }}>
        §f
      </div>
    </div>
  );
}
