import React, { useContext, useEffect, useState } from "react";
import ColourGrid from "./colour-grid";
import { ButtonsStatus } from "./button-add";
import FontFormater from "./FontFormater";
import FormType from "./FormType";
import ShopBuilder from "./menuTypes/shop/ShopBuilder";
import { v4 as uuidv4 } from "uuid";

export default function ButtonCreated({ idNum, deleteButton, passInfo, sessionID }) {
  let list = useContext(ButtonsStatus);
  let audio = new Audio("/minecraft_click3.mp3");
  const [colour, setColour] = useState("#eee090");
  const [show, toggleShow] = useState(false);
  const [formatMenu, toggleFormat] = useState(false);
  const [checkBoxes, setCheckboxes] = useState({ bold: false, italic: false, random: false });
  const [formType, setFormType] = useState({ shop: false, warp: false, home: false, money: false });
  const [typeMenu, setTypeMenu] = useState(false);
  const [text, setText] = useState("");

  function textBoxValue(e) {
    setText(e.target.value);
  }

  let infoObject = {
    id: idNum,
    colour: colour,
    checkBoxes: checkBoxes,
    formType: formType,
    typeMenu: typeMenu,
    text: text,
  };

  useEffect(() => {
    passInfo(infoObject);
  });

  function formTypeSelect(type) {
    console.log("EEE", type);
    // eslint-disable-next-line default-case
    switch (type) {
      case "shop":
        setFormType({ shop: true, warp: false, home: false, money: false });
        break;
      case "home":
        setFormType({ shop: false, warp: false, home: true, money: false });
        break;
      case "warp":
        setFormType({ shop: false, warp: true, home: false, money: false });
        break;
      case "money":
        setFormType({ shop: false, warp: false, home: false, money: true });
        break;
    }
  }

  function updateCheckboxBold() {
    setCheckboxes({ bold: !checkBoxes.bold, italic: checkBoxes.italic, random: checkBoxes.random });
  }
  function updateCheckboxItalic() {
    setCheckboxes({ bold: checkBoxes.bold, italic: !checkBoxes.italic, random: checkBoxes.random });
  }
  function updateCheckboxRandom() {
    setCheckboxes({ bold: checkBoxes.bold, italic: checkBoxes.italic, random: !checkBoxes.random });
  }

  const handleChange = () => {
    toggleShow(!show);
    audio.play();
    console.log(idNum);
  };
  const toggleFormatMenu = () => {
    toggleFormat(!formatMenu);
    audio.play();
  };
  const toggleTypeMenu = () => {
    setTypeMenu(!typeMenu);
    audio.play();
  };

  const del = () => {
    deleteButton(idNum, list);
  };

  function colourChosen(newColour) {
    setColour(newColour);
    console.log(newColour);
    handleChange();
  }
  return (
    <>
      <div className="button-edit">
        <div className="options-container">
          <input type="text" placeholder="Button Text" maxLength="30" style={{ color: colour }} onChange={(e) => textBoxValue(e)} value={text} />
          {show ? <ColourGrid colourChosen={colourChosen}></ColourGrid> : undefined}
          <button onClick={handleChange} title="Colour">
            🎨
          </button>
          <button onClick={toggleFormatMenu} title="Text Style">
            💬
          </button>
          <button onClick={toggleTypeMenu} title="Type Of Form To Make">
            📜
          </button>
          <button onClick={del} title="Delete">
            ❌
          </button>
        </div>
        <div className="options-parent">
          {formatMenu ? <FontFormater checkBoxStatus={checkBoxes} updateCheckboxBold={updateCheckboxBold} updateCheckboxItalic={updateCheckboxItalic} updateCheckboxRandom={updateCheckboxRandom}></FontFormater> : undefined}
          {typeMenu ? <FormType formTypeSelect={formTypeSelect} formType={formType}></FormType> : undefined}
        </div>
        {formType.shop ? <ShopBuilder sessionID={sessionID} shopId={idNum}></ShopBuilder> : undefined}
      </div>
      <hr></hr>
    </>
  );
}
