import React from "react";
import ButtonAdd from "./comps/button-add";

export default function Main() {
  return (
    <div className="builder">
      <h1>GUI Builder</h1>
      <ButtonAdd></ButtonAdd>
    </div>
  );
}
