import React, { useContext, useEffect, useState } from "react";
import ButtonCreated from "./button-created";
import { v4 as uuidv4 } from "uuid";
import BodyText from "./BodyText";
import Footer from "../../../components/Footer";
const sessionID = uuidv4();

let i = 0;
const ButtonsStatus = React.createContext();
const TextStatus = React.createContext();
// const ButtonsUpdate = React.createContext();

export default function ButtonAdd() {
  useEffect(() => {
    console.info("storage cleared");
    localStorage.clear();
  }, []);

  const [buttons, setButton] = useState([]);
  const [textField, setText] = useState([]);

  const audio = new Audio("/minecraft_click3.mp3");

  const generatePack = () => {
    audio.play();
    console.log(localStorage.length);

    for (let i = 0; i < localStorage.length; i++) {
      console.log(localStorage.getItem(`${i + 1}`));
      console.log(localStorage.getItem(`shop:${i + 1}`));
    }
  };
  // function buttonCreated(num) {
  //   addButton(i);
  // }
  function deleteButton(id, list) {
    console.log(list, id);
    localStorage.removeItem(`${id}`);
    setButton(list.filter((item, i) => item.props.idNum !== id));
  }

  function passInfo(info) {
    localStorage.setItem(`${info.id}`, JSON.stringify(info));
  }

  const start = () => {
    i++;

    console.log("e", i);
    audio.play();
    setButton(buttons.concat(<ButtonCreated sessionID={sessionID} passInfo={passInfo} idNum={i} deleteButton={deleteButton} key={uuidv4()}></ButtonCreated>));
  };

  const addText = () => {
    if (textField.length === 0) {
      audio.play();
      setText(<BodyText deleteText={deleteText}></BodyText>);
    }
  };

  const deleteText = () => {
    setText([]);
  };

  return (
    <>
      <div>
        <button className="button-generic" onClick={start}>
          Add Button
        </button>
        <button className="button-generic" onClick={addText}>
          Add Body Text
        </button>
        <TextStatus.Provider value={textField}>
          <div>{textField}</div>
        </TextStatus.Provider>
        <ButtonsStatus.Provider value={buttons}>
          <div>{buttons}</div>
        </ButtonsStatus.Provider>
        <br />
        <br />
        <br />
        <button className="button-generic" onClick={generatePack}>
          Generate
        </button>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer></Footer>
    </>
  );
}

export { ButtonsStatus };
